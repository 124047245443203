import { TableCell } from "@mui/material";
import { AutocompleteArrayInput, AutocompleteInput, CreateButton, Datagrid, DateField, DateInput, EditButton, FilterButton, List, NumberField, NumberInput, ReferenceArrayInput, ReferenceField, ReferenceInput, SelectArrayInput, SelectField, SelectInput, ShowButton, TextField, TextInput, TopToolbar } from "react-admin";
import { PaymentMethods, PaymentStatus } from "../../../core/Payments";

const PaymentFilters = [
    <ReferenceInput alwaysOn key="userCpf" source="userId" reference="users">
        <AutocompleteArrayInput optionText="cpf" fullWidth source="cpf" label="CPF" />
    </ReferenceInput>,

    <ReferenceArrayInput alwaysOn key="userName" source="userId" reference="users">
        <AutocompleteArrayInput optionText="name" fullWidth source="name" label="resources.payments.filters.userName" />
    </ReferenceArrayInput>,

    <ReferenceArrayInput source="raffleId" label="resources.payments.filters.raffleTitle" reference="raffles">
        <SelectArrayInput fullWidth optionText="title" label="resources.payments.filters.raffleTitle" />
    </ReferenceArrayInput>,

    <ReferenceInput alwaysOn key="userEmail" label="resources.payments.filters.userEmail" source="userId" reference="users">
        <AutocompleteArrayInput optionText="email" fullWidth source="email" label="resources.payments.filters.userEmail" />
    </ReferenceInput>,

    <NumberInput fullWidth source="id" label="resources.payments.filters.paymentId" />,

    <DateInput fullWidth source="createdAt" label="resources.payments.filters.createdAt" />,

    <SelectInput fullWidth source="status" label="resources.payments.filters.status" choices={PaymentStatus} />,
    <SelectInput fullWidth source="method" label="resources.payments.filters.method" choices={PaymentMethods} />,

    <TextInput fullWidth source="uuid" label="resources.payments.filters.uuid" />,

    <ReferenceArrayInput source="coordinatorId" label="resources.payments.filters.coordinator" reference="affiliates" filter={{ role: "COORDINATOR" }}>
        <AutocompleteInput fullWidth optionText="name" label="resources.payments.filters.coordinator" />
    </ReferenceArrayInput>,

    <ReferenceArrayInput source="managerId" label="resources.payments.filters.manager" reference="affiliates" filter={{ role: "MANAGER" }}>
        <AutocompleteInput fullWidth optionText="name" label="resources.payments.filters.manager" />
    </ReferenceArrayInput>
];

const PaymentActions = () => (
    <TopToolbar>
        <CreateButton label="Registrar pagamento" />
        <FilterButton/>
    </TopToolbar>
);

export const PaymentList = () => (
    <List
        filters={PaymentFilters}
        actions={<PaymentActions/>}
    >
        <Datagrid rowClick="show">
            <TextField source="id" />

            <ReferenceField source="userId" reference="users">
                <TextField source="name" />
            </ReferenceField>

            <SelectField source="method" choices={PaymentMethods} />
            <SelectField source="status" choices={PaymentStatus} />

            <NumberField source="value" options={{ style: "currency", currency: "BRL" }} />

            <ReferenceField source="affiliateId" sortable={false} reference="affiliates">
                <TextField source="name" />
            </ReferenceField>

            <DateField showTime source="createdAt" />

            <TableCell style={{ borderBottom: "none" }} padding="none">
                <ShowButton />
                <EditButton />
            </TableCell>
        </Datagrid>
    </List>
);
