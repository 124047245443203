import Box from "@mui/material/Box";
import { useState } from "react";

import {
    DashboardMenuItem,
    Menu,
    MenuProps,
    useGetIdentity,
    usePermissions,
    useSidebarState,
    useTranslate,
} from "react-admin";

import AccountBalance from "@mui/icons-material/AccountBalance";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Dns from "@mui/icons-material/Dns";
import FormatListBulleted from "@mui/icons-material/FormatListBulleted";
import Group from "@mui/icons-material/Group";
import GroupsIcon from '@mui/icons-material/Groups';

import { PaymentsResource } from "./resources/Payments";
import { UserLuckyNumbersResource, UsersResource } from "./resources/Users";

import { Code, FormatListNumbered, Handyman, HistoryEdu } from "@mui/icons-material";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import SubMenu from "./SubMenu";
import { AffiliateActivityResource, AffiliatesResource, ResellersMaterialResource } from "./resources/Affiliates";
import { CouponsResource } from "./resources/Coupons";
import { DailyRafflesResource } from "./resources/DailyRaffles";
import { FinancialsResource } from "./resources/Financials";
import { ManagedBatchesResource } from "./resources/ManagedBatches";
import { MatrixesResource } from "./resources/Matrixes";
import { ConsumersResource } from "./resources/Partners";
import { RaffleCategoriesResource } from "./resources/RaffleCategories";
import { RaffleExternalResultsResource, RaffleLuckyNumbersResource, RafflePrizesResource, RaffleResultsResource, RafflesResource } from "./resources/Raffles";
import { AdminsResource } from "./resources/system/AdminsResource";
import { DatabaseResource } from "./resources/system/Database";
import { NotificationsResource } from "./resources/system/Notifications";
import { PluginsResource } from "./resources/system/Plugins";

type HttpMethods = "GET" | "POST" | "PUT" | "PATCH";

export const AppMenu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuRaffles: true,
        menuSystem: true,
        menuPlatform: true,
        menuFinancial: true,
        menuPartner: true,
        menuUsers: true,
        menuAffiliates: true,
        menuApplication: true,
        menuHelpers: true,
        menuFederalLotto: true,
        menuForms: true,
        menuReports: true,
    });

    const translate = useTranslate();
    const [open] = useSidebarState();
    const { isLoading } = usePermissions();
    const { isLoading: isLoadingIdentity, identity } = useGetIdentity();

    if (isLoading || isLoadingIdentity) {
        return null;
    }

    const handleToggle = (menu: keyof typeof state) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    /**
     * Checks if the user has access to any given resources.
     * @param resources The resources to check.
     * @returns
     */
    const canAccess = (...resources: (string | { method: HttpMethods, resource: string })[]) => {
        // If the user is not logged in, return false
        if (!identity) {
            return false;
        }

        // Iterate over all resources
        for (const r of resources) {
            let name = r;

            // If the resource is an object, get the method and resource name
            if (typeof name === "object") {
                name = `${name.method} ${name.resource}`;
            }

            // Check if the user has access to the resource
            if (identity.acl === true || identity.acl?.[name]?.enabled === true) {
                return true;
            }
        }

        return false;
    };

    return (
        <Box
            sx={{
                width: open ? 275 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create("width", {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            {/* Reports */}
            { canAccess({ method: "GET", resource: "dashboard" }) && <SubMenu
                handleToggle={() => handleToggle("menuReports")}
                isOpen={state.menuReports}
                name="menus.reports"
                icon={<AssessmentIcon />}
                dense={dense}
            >
                { canAccess({ method: "GET", resource: "dashboard" }) && <DashboardMenuItem /> }
            </SubMenu> }

            {/* Users */}
            { canAccess("users", "userLuckyNumbers") && <SubMenu
                handleToggle={() => handleToggle("menuUsers")}
                isOpen={state.menuUsers}
                name="menus.users"
                icon={<Group />}
                dense={dense}
            >
                <Menu.Item
                    to="/users"
                    state={{ _scrollToTop: true }}
                    primaryText={translate("resources.users.name", { smart_count: 2 })}
                    leftIcon={<UsersResource.icon />}
                    dense={dense}

                    hidden={!canAccess("users")}
                />

                <Menu.Item
                    to="/userLuckyNumbers"
                    state={{ _scrollToTop: true }}
                    primaryText={translate("resources.userLuckyNumbers.name", { smart_count: 2 })}
                    leftIcon={<UserLuckyNumbersResource.icon />}
                    dense={dense}

                    hidden={!canAccess("useLuckyNumbers")}
                />
            </SubMenu> }

            {/* Resellers */}
            { canAccess("affiliates", "resellersMaterial", "affiliateActivity", "managedPhysicalRanges", "affiliatePhysicalRanges") &&
                <SubMenu
                    handleToggle={() => handleToggle("menuAffiliates")}
                    isOpen={state.menuAffiliates}
                    name="menus.affiliates"
                    icon={<AffiliatesResource.icon />}
                    dense={dense}
                >
                    <Menu.Item
                        to="/affiliates"
                        state={{ _scrollToTop: true }}
                        primaryText={translate("resources.affiliates.name", { smart_count: 2 })}
                        leftIcon={<AffiliatesResource.icon />}
                        dense={dense}

                        hidden={!canAccess("affiliates")}
                    />

                    <Menu.Item
                        to="/resellersMaterial"
                        state={{ _scrollToTop: true }}
                        primaryText={translate("resources.resellersMaterial.name", { smart_count: 2 })}
                        leftIcon={<ResellersMaterialResource.icon />}
                        dense={dense}

                        hidden={!canAccess("resellersMaterial")}
                    />

                    <Menu.Item
                        to="/affiliateActivity"
                        state={{ _scrollToTop: true }}
                        primaryText={translate("resources.affiliateActivity.name", { smart_count: 2 })}
                        leftIcon={<AffiliateActivityResource.icon />}
                        dense={dense}

                        hidden={!canAccess("affiliateActicity")}
                    />

                    <Menu.Item
                        to="/managedPhysicalRanges"
                        state={{ _scrollToTop: true }}
                        primaryText={translate("resources.managedPhysicalRanges.name", { smart_count: 2 })}
                        leftIcon={<ManagedBatchesResource.icon />}
                        dense={dense}

                        hidden={!canAccess("managedPhysicalRanges")}
                    />

                    <Menu.Item
                        to="/affiliatePhysicalRanges"
                        state={{ _scrollToTop: true }}
                        primaryText={translate("resources.affiliatePhysicalRanges.name", { smart_count: 2 })}
                        leftIcon={<ManagedBatchesResource.icon />}
                        dense={dense}

                        hidden={!canAccess("affiliatePhysicalRanges")}
                    />
                </SubMenu>
            }

            {/* Financial */}
            <SubMenu
                handleToggle={() => handleToggle("menuFinancial")}
                isOpen={state.menuFinancial}
                name="menus.financial"
                icon={<AccountBalance />}
                dense={dense}
            >
                <Menu.Item
                    to="/payments"
                    state={{ _scrollToTop: true }}
                    primaryText={translate("resources.payments.name", { smart_count: 2 })}
                    leftIcon={<PaymentsResource.icon />}
                    dense={dense}

                    hidden={!canAccess("payments")}
                />

                <Menu.Item
                    to="/financials"
                    state={{ _scrollToTop: true }}
                    primaryText={translate("resources.financials.name", { smart_count: 2 })}
                    leftIcon={<FinancialsResource.icon />}
                    dense={dense}

                    hidden={!canAccess("financials")}
                />

                <Menu.Item
                    to="/coupons"
                    state={{ _scrollToTop: true }}
                    primaryText={translate("resources.coupons.name", { smart_count: 2 })}
                    leftIcon={<CouponsResource.icon />}
                    dense={dense}

                    hidden={!canAccess("coupons")}
                />
            </SubMenu>

            {/* API Partner */}
            { canAccess("partners") &&
            <SubMenu
                handleToggle={() => handleToggle("menuPartner")}
                isOpen={state.menuPartner}
                name="menus.partners"
                icon={<GroupsIcon />}
                dense={dense}
            >
                <Menu.Item
                    to="/partners"
                    state={{ _scrollToTop: true }}
                    primaryText={translate("resources.partners.name", { smart_count: 2 })}
                    // temporary icon
                    leftIcon={<ConsumersResource.icon />}
                    dense={dense}

                    hidden={!canAccess("partners")}
                />
            </SubMenu> }

            {/* Raffles */}
            <SubMenu
                handleToggle={() => handleToggle("menuRaffles")}
                isOpen={state.menuRaffles}
                name="menus.raffles"
                icon={<FormatListBulleted />}
                dense={dense}
            >
                { canAccess("raffleCategories") && <Menu.Item
                    to="/raffleCategories"
                    state={{ _scrollToTop: true }}
                    primaryText={translate("resources.raffleCategories.name", { smart_count: 2 })}
                    leftIcon={<RaffleCategoriesResource.icon />}
                    dense={dense}
                /> }

                { canAccess("raffles") && <Menu.Item
                    to="/raffles"
                    state={{ _scrollToTop: true }}
                    primaryText={translate("resources.raffles.name", { smart_count: 2 })}
                    leftIcon={<RafflesResource.icon />}
                    dense={dense}
                /> }

                { canAccess("dailyRaffles") && <Menu.Item
                    to="/dailyRaffles"
                    state={{ _scrollToTop: true }}
                    primaryText={translate("resources.dailyRaffles.name", { smart_count: 2 })}
                    leftIcon={<DailyRafflesResource.icon />}
                    dense={dense}
                /> }

                { canAccess("rafflePrizes") && <Menu.Item
                    to="/rafflePrizes"
                    state={{ _scrollToTop: true }}
                    primaryText={translate("resources.rafflePrizes.name", { smart_count: 2 })}
                    leftIcon={<RafflePrizesResource.icon />}
                    dense={dense}
                /> }

                { canAccess("raffleResults") && <Menu.Item
                    to="/raffleResults"
                    state={{ _scrollToTop: true }}
                    primaryText={translate("resources.raffleResults.name", { smart_count: 2 })}
                    leftIcon={<RaffleResultsResource.icon />}
                    dense={dense}

                    hidden={!canAccess("raffleResults")}
                /> }

                {
                    canAccess("raffleExternalResults") && <Menu.Item
                        to="/raffleExternalResults"
                        state={{ _scrollToTop: true }}
                        primaryText={translate("resources.raffleExternalResults.name", { smart_count: 2 })}
                        leftIcon={<RaffleExternalResultsResource.icon />}
                        dense={dense}

                        hidden={!canAccess("raffleExternalResults")}
                    />
                }

                { canAccess("raffleLuckyNumbers") && <Menu.Item
                    to="/raffleLuckyNumbers"
                    state={{ _scrollToTop: true }}
                    primaryText={translate("resources.raffleLuckyNumbers.name", { smart_count: 2 })}
                    leftIcon={<RaffleLuckyNumbersResource.icon />}
                    dense={dense}
                /> }

                { canAccess("matrix") && <Menu.Item
                    to="/matrix"
                    state={{ _scrollToTop: true }}
                    primaryText={translate("resources.matrix.name", { smart_count: 2 })}
                    leftIcon={<MatrixesResource.icon />}
                    dense={dense}
                /> }
            </SubMenu>

            <SubMenu
                handleToggle={() => handleToggle("menuForms")}
                isOpen={state.menuForms}
                name="menus.forms"
                icon={<FormatListNumbered />}
                dense={dense}
            >
                <Menu.Item
                    to="/formSubmissions"
                    state={{ _scrollToTop: true }}
                    primaryText={translate("forms.vivaSuaHistoria.name", { smart_count: 2 })}
                    leftIcon={<HistoryEdu />}
                    dense={dense}
                />
            </SubMenu>

            <SubMenu
                handleToggle={() => handleToggle("menuHelpers")}
                isOpen={state.menuHelpers}
                name="menus.helpers"
                icon={<Dns />}
                dense={dense}
            >
                <Menu.Item
                    to="/helpers/result-image-generator"
                    state={{ _scrollToTop: true }}
                    primaryText={translate("helpers.resultImageGenerator.name", { smart_count: 2 })}
                    leftIcon={<PictureAsPdf />}
                    dense={dense}
                />
            </SubMenu>

            {
                !isLoading && (identity as any).role === "SUPERADMIN" &&
                    <>
                        {/* Plataform */}
                        <SubMenu
                            handleToggle={() => handleToggle("menuPlatform")}
                            isOpen={state.menuPlatform}
                            name="menus.platform"
                            icon={<Dns />}
                            dense={dense}
                        >

                            <Menu.Item
                                to="/ambassadors"
                                primaryText={translate("resources.ambassadors.name")}
                                leftIcon={<Group />}
                                dense={dense}
                            />

                            <Menu.Item
                                to="/notifications"
                                state={{ _scrollToTop: true }}
                                primaryText={translate("resources.notifications.name", { smart_count: 2 })}
                                leftIcon={<NotificationsResource.icon />}
                                dense={dense}

                                hidden={!canAccess("notifications")}
                            />

                            <Menu.Item
                                to="/admins"
                                state={{ _scrollToTop: true }}
                                primaryText={translate("resources.admins.name", { smart_count: 2 })}
                                leftIcon={<AdminsResource.icon />}
                                dense={dense}

                                hidden={!canAccess("admins")}
                            />
                        </SubMenu>

                        <SubMenu
                            handleToggle={() => handleToggle("menuSystem")}
                            isOpen={state.menuSystem}
                            name="menus.system"
                            icon={<Code />}
                            dense={dense}
                        >
                            <Menu.Item
                                to="/plugins"
                                state={{ _scrollToTop: true }}
                                primaryText={translate("resources.plugins.name", { smart_count: 2 })}
                                leftIcon={<PluginsResource.icon />}
                                dense={dense}

                                hidden={!canAccess("plugins")}
                            />

                            <Menu.Item
                                to="/helpers/app-control"
                                state={{ _scrollToTop: true }}
                                primaryText={translate("helpers.appControl.name", { smart_count: 2 })}
                                leftIcon={<Handyman />}
                                dense={dense}
                            />

                            <Menu.Item
                                to="/database"
                                state={{ _scrollToTop: true }}
                                primaryText={translate("resources.database.name", { smart_count: 2 })}
                                leftIcon={<DatabaseResource.icon />}
                                dense={dense}
                            />

                            <Menu.Item
                                to="/gateways/mercadopago/marketplace/connector"
                                state={{ _scrollToTop: true }}
                                primaryText={"[MP] Conector do Marketplace"}
                                leftIcon={<DatabaseResource.icon />}
                                dense={dense}
                            />
                        </SubMenu>
                    </>
            }
        </Box>
    );
};
