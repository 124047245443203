import { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { createDataProviderRequest, objectToQueryString } from "../../core/Actions";

import { Menu, MenuItem, Popover, Tab, Tabs, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AutocompleteInput, Button, Form, ReferenceInput, useAuthProvider } from "react-admin";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { DateTime } from "luxon";
import { Configuration } from "../../config";
import { removeNullOrUndefined } from "../../utils/ObjectUtils";
import { hasAccess } from "../parts/ResourceWithPermission";
import { GraphTab } from "./dashboard/GraphTab";
import { MetaSelector, MetaSelectorTag } from "./dashboard/MetaSelector";
import { MetricsTab } from "./dashboard/MetricsTab";
import { PhysicalSalesTab } from "./dashboard/PhysicalSalesTab";
import { RegionsTab } from "./dashboard/RegionsTab";
import { ResellersTab } from "./dashboard/ResellersTab";

const TabPanel = (props: {
    children: any;
    index: number;
    value: number;
}) => {
    return (
        <div
            className="py-5"
            id={`dashboard-panel-${props.index}`}
            hidden={props.index !== props.value}
        >
            {
                props.value === props.index && (
                    props.children
                )
            }
        </div>
    );
};

interface IReports {
    totalPaymentsCount: number;
    totalApprovedCount: number;
    totalApprovedValue: number;
    totalNonApprovedValue: number;
}

export const DashboardContext = createContext<{
    raffleId: number | null;
    queryData: IQueryData | null;
    isLoading: boolean;
}>({
    raffleId: null,
    queryData: null,
    isLoading: true
});

export interface IQueryData {
    startDate?: string | null;
    endDate?: string | null;
    raffleId?: number | string | null;
    kind?: string | null;
    managerId?: number | null;
    coordinatorId?: number | null;
    paymentMeta: MetaSelectorTag[];
}

export const useDashboardContext = () => useContext(DashboardContext);

export const Dashboard = () => {
    const [canAccess, setCanAccess] = useState(false);
    const provider = useAuthProvider();

    // Run once
    useEffect(() => {
        provider.getIdentity!().then((user) => {
            const canAccessDashboard = hasAccess(user.acl, "GET dashboard.enabled");

            setCanAccess(canAccessDashboard);
        });
    }, []);

    return (
        canAccess ?
            <DashboardContainer /> :
            <div className="p-5">
                <h5 className="block mb-3 text-3xl font-bold">Olá! Boas vindas ao LuckyMaker.</h5>
                Por gentileza, escolha uma opção ao lado para começar.
            </div>
    );
};

const DashboardContainer = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [metrics, setMetrics] = useState<Record<string, any> | null>({});
    const [reports, setReport] = useState<{
        group: "day" | "month" | "hour";
        report: (IReports & {
            hour: number;
            day: number;
            month: number;

            metadata?: Record<string, IReports>;
        })[];
    } | null>({
        group: "day",
        report: []
    });

    const [raffle, setRaffle] = useState<number | null>(null);
    const [lastRaffle, setLastRaffle] = useState<number | null>(null);

    const [dateFilterAnchorEl, setDateFilterAnchorEl] = useState(null);
    const [tagsFilterAnchorEl, setTagsFilterAnchorEl] = useState(null);
    const [exportAnchorEl, setExportAnchorEl] = useState(null);
    const [managerName, setManagerName] = useState(null);
    const [coordinatorName, setCoordinatorName] = useState(null);

    const [queryData, setQueryData] = useState<IQueryData>({
        startDate: DateTime.now().setZone("UTC-3").minus({ months: 1 }).toISO(),
        endDate: null,
        raffleId: raffle,
        paymentMeta: [],
        kind: null,
        managerId: undefined,
        coordinatorId: undefined
    });

    const [managerCoordinatorAnchorEl, setManagerCoordinatorAnchorEl] = useState(null);

    const handleManagerCoordinatorClick = (event) => {
        setManagerCoordinatorAnchorEl(event.currentTarget);
    };

    const handleManagerCoordinatorClose = () => {
        setManagerCoordinatorAnchorEl(null);
    };

    const [subMenuAnchor, setSubMenuAnchor] = useState(null);

    const handleSubMenuClick = (event) => {
        setSubMenuAnchor(event.currentTarget);
    };

    const handleSubMenuClose = () => {
        setSubMenuAnchor(null);
    };

    type KnownExporterFormats = "ValeSorte" | "ViaCapVND" | "ViaCapVNDDiario" | "ViaCapContemplados" | "Drawer" | "ViaCapPedido" | "ViaCapAtivos";

    /**
     * Performs a file export.
     */
    function performExport(exportType: KnownExporterFormats, queryParams?: Record<string, string>) {
        const downloadUrl: URL = objectToQueryString(
            new URL(Configuration.baseUrl + "/raffles/" + raffle + "/actions/exportTo" + exportType),
            {
                ...queryData,
                access_token: localStorage.getItem("admin-token")
            }
        );

        // Sets the query params
        if (queryParams && typeof queryParams === "object") {
            for (const key in queryParams) {
                downloadUrl.searchParams.set(key, queryParams[key]);
            }
        }

        window.open(downloadUrl);
    }

    const refreshData = () => {
        // Ignore if has no raffle selected
        if (!raffle) {
            setIsLoading(false);
            return;
        }

        // Copy the query data
        const queryToSend = removeNullOrUndefined({
            ...queryData,
            raffleId: raffle
        });

        // Set in loading state
        setIsLoading(true);

        let loaded = 0;

        const checkLoaded = () => {
            loaded++;

            if (loaded === 2) {
                setIsLoading(false);
            }
        };

        // Retrieve the dashboard data for it
        createDataProviderRequest("get", "dashboard", queryToSend)
            .then(async (data) => {
                const jsonData = (await data.json());

                setMetrics(jsonData);
                checkLoaded();
            });

        // Retrieve the reports data for it
        createDataProviderRequest("get", "report", queryToSend)
            .then(async (data) => {
                const jsonData = (await data.json());

                setReport(jsonData);
                checkLoaded();
            });
    };

    // When the selected raffle or query data changes
    useEffect(refreshData, [lastRaffle, raffle, queryData]);
    useQuery(["dashboardData"], refreshData);

    // Function to fetch affiliate data
    const fetchAffiliateData = async (id, attributes, setName) => {
        // If no ID, return
        if (!id) {
            return;
        }

        // Fetch the data and set the name of the affiliate
        createDataProviderRequest("get", `affiliates/${id}`, null, {
            query: { attributes },
        }).then(async (data) => {
            const jsonData = await data.json();
            setName(jsonData?.name);
        });
    };

    // Run when the manager changes
    useEffect(() => {
        fetchAffiliateData(queryData.managerId, ["id", "name"], setManagerName);
    }, [queryData.managerId]);

    // Run when the coordinator changes
    useEffect(() => {
        fetchAffiliateData(queryData.coordinatorId, ["id", "name", "managerId"], setCoordinatorName);
    }, [queryData.coordinatorId]);
    
    // Run only one time to retrieve the latest MAIN raffle
    useEffect(() => {
        createDataProviderRequest("get", "raffles", null, {
            query: {
                range: [0, 1],
                attributes: ["id"],
                sort: ["id", "DESC"],
                ...removeNullOrUndefined(queryData),
                // By kind MAIN
                kind: "MAIN",
                status: "OPEN"
            }
        })
            .then(async (data) => {
                const jsonData = (await data.json());

                setLastRaffle(jsonData[0].id);
                setRaffle(jsonData[0].id);
            });
    }, []);

    // When updating the raffle ID, update also the query data
    useEffect(() => {
        setQueryData({
            ...queryData,
            raffleId: raffle
        });

    }, [raffle]);

    let data = reports?.report.map((report) => {
        const date = DateTime.fromObject({
            year: DateTime.now().setZone("UTC-3").year,
            month: report.month,
            day: report.day,
            hour: report.hour
        }).minus({ hours: 3 });

        return {
            ...report,

            hour: date.hour,
            day: date.day,
            month: date.month,
            year: date.year,

            mensurableMetric: {
                hour() {
                    return this.day() + " " + String(date.hour).padStart(2, "0") + "h00";
                },

                day() {
                    return String(date.day).padStart(2, "0") + "/" + this.month();
                },

                month() {
                    return String(date.month).padStart(2, "0");
                }
            }[reports.group]()
        };
    });

    const [currentTab, setCurrentTab] = useState(0);

    return (
        <DashboardContext.Provider
            value={{
                raffleId: raffle,
                queryData: queryData,
                isLoading: isLoading
            }}
        >
            <div className="container mx-auto my-5 text-black">
                <header className="pb-3 mb-3 border-b border-b-1">
                    <div className="flex items-center">
                        <div className="flex items-center flex-auto">
                            <div className="inline-block p-2 mr-3 bg-white rounded-md shadow text-primary">
                                <i className="fa fa-fw fa-dashboard"></i>
                            </div>

                            <strong className="flex-auto text-sm text-neutral-500">{Configuration.title || 'Dashboard'} </strong>
                        </div>

                        <div className="flex-auto">
                            <Form className="flex items-center gap-3 space-x-3">
                                <Button
                                    aria-haspopup="true"
                                    onClick={(e) => setExportAnchorEl(e.target as any)}
                                >
                                    <i className="fa fa-fw fa-download"></i>
                                </Button>

                                <Button
                                    aria-haspopup="true"
                                    onClick={(e) => setDateFilterAnchorEl(e.target as any)}
                                >
                                    <i className="fa fa-fw fa-calendar"></i>
                                </Button>

                                <Button
                                    aria-haspopup="true"
                                    onClick={(e) => setTagsFilterAnchorEl(e.target as any)}
                                >
                                    <i className="fa fa-fw fa-tags"></i>
                                </Button>

                                <Button
                                    aria-haspopup="true"
                                    onClick={handleManagerCoordinatorClick}
                                >
                                    <i className="fa fa-fw fa-users"></i>
                                </Button>

                                <Menu
                                    id="tags-filter-menu"
                                    anchorEl={tagsFilterAnchorEl}
                                    keepMounted
                                    open={Boolean(tagsFilterAnchorEl)}
                                    onClose={() => setTagsFilterAnchorEl(null)}
                                >
                                    <MetaSelector
                                        raffle={raffle}
                                        onAddTag={(tag) => {
                                            setQueryData({
                                                ...queryData,
                                                paymentMeta: [
                                                    ...queryData.paymentMeta || [],
                                                    tag
                                                ]
                                            });

                                            setTagsFilterAnchorEl(null);
                                        }}
                                    />
                                </Menu>

                                <Menu
                                    id="date-filter-menu"
                                    anchorEl={dateFilterAnchorEl}
                                    keepMounted
                                    open={Boolean(dateFilterAnchorEl)}
                                    onClose={() => setDateFilterAnchorEl(null)}
                                >
                                    <div className="p-3">
                                        <div className="flex mb-3">
                                            <Button
                                                label="Hoje"
                                                onClick={() => {
                                                    setQueryData({
                                                        ...queryData,
                                                        startDate: DateTime.now().setZone("UTC-3").startOf("day").toISO(),
                                                        endDate: undefined
                                                    });
                                                }}
                                            />

                                            <Button
                                                label="Ontem"
                                                onClick={() => {
                                                    const yesterday = DateTime.now().setZone("UTC-3").minus({ days: 1 });

                                                    setQueryData({
                                                        ...queryData,
                                                        startDate: yesterday.startOf("day").toISO(),
                                                        endDate: yesterday.endOf("day").toISO()
                                                    });
                                                }}
                                            />

                                            <Button
                                                label="Esta semana"
                                                onClick={() => {
                                                    const yesterday = DateTime.now().setZone("UTC-3");

                                                    setQueryData({
                                                        ...queryData,
                                                        startDate: yesterday.startOf("week").toISO(),
                                                        endDate: yesterday.endOf("week").endOf("day").toISO()
                                                    });
                                                }}
                                            />

                                            <Button
                                                label="7d"
                                                onClick={() => {
                                                    const yesterday = DateTime.now().setZone("UTC-3").minus({ days: 7 });

                                                    setQueryData({
                                                        ...queryData,
                                                        startDate: yesterday.startOf("day").toISO(),
                                                        endDate: DateTime.now().setZone("UTC-3").endOf("day").toISO()
                                                    });
                                                }}
                                            />

                                            <Button
                                                label="30d"
                                                onClick={() => {
                                                    const yesterday = DateTime.now().setZone("UTC-3").minus({ days: 30 });

                                                    setQueryData({
                                                        ...queryData,
                                                        startDate: yesterday.startOf("day").toISO(),
                                                        endDate: DateTime.now().setZone("UTC-3").endOf("day").toISO()
                                                    });
                                                }}
                                            />
                                        </div>

                                        <div className="flex items-center">
                                            <LocalizationProvider
                                                adapterLocale="pt-BR"
                                                dateAdapter={AdapterLuxon}
                                            >
                                                <DateTimePicker
                                                    label="De"
                                                    value={queryData.startDate}
                                                    onChange={(value) => setQueryData({
                                                        ...queryData,
                                                        startDate: (value as any as DateTime).toISO()
                                                    })}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    inputFormat="dd/MM/yyyy HH:mm"
                                                />

                                                <span className="mx-3 text-sm text-neutral-500">até</span>

                                                <DateTimePicker
                                                    label="Até"
                                                    value={queryData.endDate}
                                                    onChange={(value) => setQueryData({
                                                        ...queryData,
                                                        endDate: (value as any as DateTime).toISO()
                                                    })}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    inputFormat="dd/MM/yyyy HH:mm"
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </Menu>

                                <Popover
                                    open={Boolean(managerCoordinatorAnchorEl)}
                                    anchorEl={managerCoordinatorAnchorEl}
                                    onClose={handleManagerCoordinatorClose}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                >
                                    <div className="p-3">
                                        <p className="mb-3 text-muted">
                                            Escolha o Gerente e o Coordenador para filtrar.
                                        </p>

                                        <div>
                                            <ReferenceInput
                                                source="managerId"
                                                reference="affiliates"
                                                filter={{ role: "MANAGER" }}
                                            >
                                                <AutocompleteInput
                                                    optionText="name"
                                                    label="Gerente (Nome, documento ou e-mail)"
                                                    onChange={(value) => setQueryData((data) => ({ ...data, managerId: value }))}
                                                    value={queryData.managerId ? { id: queryData.managerId } : null}
                                                    placeholder="Selecione o Gerente"
                                                />
                                            </ReferenceInput>
                                        </div>

                                        <div>
                                            <ReferenceInput
                                                source="coordinatorId"
                                                reference="affiliates"
                                                filter={{ role: "COORDINATOR" }}
                                            >
                                                <AutocompleteInput
                                                    optionText="name"
                                                    label="Coordenador (Nome, documento ou e-mail)"
                                                    onChange={(value) => setQueryData((data) => ({ ...data, coordinatorId: value }))}
                                                    value={queryData.coordinatorId ? { id: queryData.coordinatorId } : null}
                                                    placeholder="Selecione o Coordenador"
                                                />
                                            </ReferenceInput>
                                        </div>
                                    </div>
                                </Popover>

                                <Menu
                                    id="export-menu"
                                    anchorEl={exportAnchorEl}
                                    keepMounted
                                    open={Boolean(exportAnchorEl)}
                                    onClose={() => {
                                        setExportAnchorEl(null);
                                    }}
                                >
                                    <MenuItem className="flex-col items-start" onClick={() => {
                                        setExportAnchorEl(null);
                                        performExport("Drawer");
                                    }}>
                                        <span className="w-full text-left">Exportar para sorteador</span>
                                        <small className="w-full text-xs text-left text-black/40">Usa todos os filtros aplicados</small>
                                    </MenuItem>

                                    <MenuItem
                                        onClick={(e) => {
                                            handleSubMenuClick(e);
                                        }}
                                    >
                                        <ArrowLeftIcon className="mr-3" />
                                        Exportar títulos vendidos
                                    </MenuItem>

                                    <Popover
                                        open={Boolean(subMenuAnchor)}
                                        anchorEl={subMenuAnchor}
                                        onClose={handleSubMenuClose}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <MenuItem className="flex-col items-start" onClick={() => {
                                            setExportAnchorEl(null);
                                            performExport("ValeSorte");
                                        }}>
                                            <span className="w-full text-left">Formato padrão</span>
                                            <small className="w-full text-xs text-left text-black/40">Usará todos os filtros aplicados</small>
                                        </MenuItem>

                                        <MenuItem className="flex-col items-start" onClick={() => {
                                            setExportAnchorEl(null);
                                            performExport("ViaCapVNDDiario");
                                        }}>
                                            <span className="w-full text-left">Formato ViaCap Diário (ViaCap Filantropia)</span>
                                            <small className="w-full text-xs text-left text-black/40">Usará todos os filtros aplicados</small>
                                        </MenuItem>

                                        <MenuItem className="flex-col items-start" onClick={() => {
                                            setExportAnchorEl(null);
                                            performExport("ViaCapAtivos", { customerCode: "DAILY" });
                                        }}>
                                            <span className="w-full text-left">Extração p/ sorteio diário (ViaCap Incentivo)</span>
                                            <small className="w-full text-xs text-left text-black/40">Usará todos os filtros aplicados</small>
                                        </MenuItem>
                                    </Popover>
                                </Menu>

                                <ReferenceInput
                                    source="raffleId"
                                    reference="raffles"
                                    filter={{
                                        kind: queryData.kind ?? undefined
                                    }}
                                >
                                    <AutocompleteInput
                                        className="w-full"
                                        optionText="title"
                                        label="Sorteio"
                                        onChange={(id) => setRaffle(id)}
                                        autoSelect
                                        clearIcon={false}
                                        defaultValue={lastRaffle}
                                        helperText={false}
                                    />
                                </ReferenceInput>
                            </Form>
                        </div>
                    </div>

                    <div className="flex items-center justify-end gap-3 my-3">
                        <Button
                            onClick={() => setQueryData({... queryData, kind: null })}
                            label="Todos"
                            color={queryData.kind === null ? "secondary": "primary"}
                        />

                        <Button
                            onClick={() => setQueryData({... queryData, kind: "SECONDARY" })}
                            label={Configuration.whitelabel?.labels.secondaryRaffles}
                            color={queryData.kind === "SECONDARY" ? "secondary": "primary"}
                        />

                        <Button
                            onClick={() => setQueryData({... queryData, kind: "MAIN" })}
                            label={Configuration.whitelabel?.labels.mainRaffles}
                            color={queryData.kind === "MAIN" ? "secondary": "primary"}
                        />
                    </div>

                    <div className="flex items-center justify-end flex-1 w-full gap-2 text-neutral-500">
                        
                        { queryData.managerId &&
                            <div className="p-2 text-xs bg-white rounded-md shadow-sm">
                                <i className="mr-1 fa fa-fw fa-filter"></i>
                                Gerente: {managerName}
                            </div>
                        }

                        { queryData.coordinatorId &&
                            <div className="p-2 text-xs bg-white rounded-md shadow-sm">
                                <i className="mr-1 fa fa-fw fa-filter"></i>
                                Coordenador: {coordinatorName}
                            </div>
                        }

                        <div className="p-2 text-xs bg-white rounded-md shadow-sm">
                            <i className="mr-1 fa fa-fw fa-filter"></i>

                            <span className="mr-1">De</span>
                            <strong>{DateTime.fromISO(queryData.startDate as any).toLocaleString({
                                day: "2-digit",
                                month: "2-digit",
                                year: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit"
                            })}</strong>
                            <span className="mx-1">até</span>
                            <strong>
                                {
                                    queryData.endDate ?
                                        DateTime.fromISO(queryData.endDate as any).toLocaleString({
                                            day: "2-digit",
                                            month: "2-digit",
                                            year: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit"
                                        }) :
                                        "agora"
                                }
                            </strong>
                        </div>

                        {
                            queryData.paymentMeta.map((meta) => (
                                <div className="p-2 ml-1 text-xs bg-white rounded-md shadow-sm">
                                    <i className="mr-1 fa fa-fw fa-filter"></i>
                                    Marcador <strong>{meta.key}</strong>
                                    {
                                        meta.value && (
                                            <>
                                                <span className="mx-1">é</span>
                                                <strong>{meta.value}</strong>
                                            </>
                                        )
                                    }

                                    <button
                                        className="ml-2 text-red-500"

                                        onClick={() => {
                                            setQueryData({
                                                ...queryData,
                                                paymentMeta: queryData.paymentMeta
                                                    .filter((data) =>
                                                        !(data.key === meta.key && data.value === meta.value)
                                                    )
                                            })
                                        }}
                                    >
                                        <i className="fa fa-fw fa-times"></i>
                                    </button>
                                </div>
                            ))
                        }
                    </div>
                </header>

                <section className="relative">
                    {
                        isLoading &&
                            <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-white/70">
                                <i className="fa fa-fw fa-spin fa-spinner fa-3x"></i>
                            </div>
                    }

                    <Tabs value={currentTab} onChange={((e, number) => setCurrentTab(number))}>
                        <Tab aria-controls="dashboard-panel-0" label="Métricas" />
                        <Tab aria-controls="dashboard-panel-1" label="Regiões" />
                        <Tab aria-controls="dashboard-panel-2" label="Gráfico" />
                        <Tab aria-controls="dashboard-panel-3" label="Venda física" />
                        <Tab aria-controls="dashboard-panel-4" label="Top 10 Revendedores" />
                    </Tabs>

                    <TabPanel value={currentTab} index={0}>
                        <MetricsTab metrics={metrics} />
                    </TabPanel>

                    <TabPanel value={currentTab} index={1}>
                        <RegionsTab />
                    </TabPanel>

                    <TabPanel value={currentTab} index={2}>
                        <GraphTab data={data} queryData={queryData}  />
                    </TabPanel>

                    <TabPanel value={currentTab} index={3}>
                        <PhysicalSalesTab metrics={metrics} />
                    </TabPanel>

                    <TabPanel value={currentTab} index={4}>
                        <ResellersTab metrics={metrics} />
                    </TabPanel>
                </section>
            </div>
        </DashboardContext.Provider>
    );
};

export default Dashboard;
