import BoltIcon from "@mui/icons-material/Bolt";
import DownloadIcon from "@mui/icons-material/Download";
import { Accordion, AccordionDetails, AccordionSummary, Box, Menu, MenuItem, Modal, Popover, Select, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Button, Confirm, DateField, EditButton, ImageField, NumberField, SelectField, Show, Tab, TabbedShowLayout, TextField, TopToolbar, useNotify, useRefresh, useShowContext, useUpdate } from "react-admin";
import { Configuration } from "../../../config";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import { createActionRequest, createDataProviderRequest } from "../../../core/Actions";
import { mapIf } from "../../../utils/GeneralUtils";
import { generateAuthenticatedURL } from "../../../utils/RequestUtils";
import { RaffleKinds, RaffleStatus } from "../Raffles";
import { RaffleDraw } from "./RaffleDraw";
import { GenerateNumbersAction, setGenerateNumbersOpen } from "./actions/GenerateNumbersAction";

const GenerationStatuses = {
    AVOIDED: "Evitado",
    STARTED: "Em andamento",
    COMPLETED: "Concluído",
    ERRORED: "Erro",
    TIMED_OUT: "Tempo esgotado"
};

interface Raffle {
    id: number;
    title: string;
    categoryId: number;
    status?: string;

    physicalBondsGeneration: {
        isRunning: boolean;
        hasAnyGenerated: boolean;
        trackingState?: {
            eta?: string;
            status?: string;
            statusExplanation?: string;
            startedAt?: string;
            finishedAt?: string;
            updatedAt?: string;
            generatedBonds?: number;
            starterNodeId?: number;
            bondsToGenerate?: number;
        }
    }
}

interface ManagedRange {
    id: number;
    title: string;
    fromBatch: number;
    toBatch: number;
}

const RaffleShowActions = () => {
    const { record } = useShowContext();

    const [closeRaffleModalVisible, setCloseRaffleModalVisible] = useState(false);
    const [integrityCheckModalVisible, setIntegrityCheckModalVisible] = useState(false);
    const [exportAnchorEl, setExportAnchorEl] = useState(null);
    const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
    const [subMenuAnchor, setSubMenuAnchor] = useState(null);
    const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
    const [showDeleteFilesConfirmation, setShowDeleteFilesConfirmation] = useState(false);

    const handleSubMenuClick = (event) => {
      setSubMenuAnchor(event.currentTarget);
    };

    const handleSubMenuClose = () => {
      setSubMenuAnchor(null);
    };

    const [update] = useUpdate("raffles");

    const notify = useNotify();
    const refresh = useRefresh();

    type KnownExporterFormats = "ValeSorte" | "ViaCapVND" | "ViaCapVNDV3" | "ViaCapContemplados" | "ViaCapAta" | "ViaCapReembolsoContemplados" | "ViaCapPedido" | "ViaCapAtivos";

    /**
     * Performs an export action.
     * @param type The export type.
     */
    function performExport(type: KnownExporterFormats, queryParams?: Record<string, string>) {
        const url: URL = new URL(
            generateAuthenticatedURL("/raffles/" + record.id + "/actions/exportTo" + type)
        );

        // Sets the query params
        if (queryParams && typeof queryParams === "object") {
            for (const key in queryParams) {
                url.searchParams.set(key, queryParams[key]);
            }
        }

        window.open(url);
    }

    /**
     * Performs a drawer file download action.
     * @param kind The file kind.
     */
    function performDrawerFileDownload(kind: "header" | "raffle" | "users") {
        window.open(
            generateAuthenticatedURL("/raffles/" + record.id + "/files/drawer/" + kind + "/download")
        );
    }

    async function performMatrixIntegrityCheck() {
        try {
            setIntegrityCheckModalVisible(false);
            notify("Aguarde enquanto a verificação é realizada");

            const response = await createActionRequest("post", "raffles", record.id, "matrixIntegrityCheck");
            const data = await response.json();

            if (data?.hadOperation) {
                notify("Verificação de problemas concluída: uma correção foi aplicada", { type: "success" });
            } else {
                notify("Verificação de problemas concluída: não há correções a serem aplicadas");
            }
        } catch(e) {
            notify((e as any).message, { type: "error" });
        }
    }

    async function clearRaffleWinners() {
        try {
            await createActionRequest("post", "raffles", record.id, "resetResults");

            notify(`Todos os ganhadores e números chamados do sorteio ${record.id} foram removidos.`, { type: "success" });
        } catch(e) {
            notify((e as any).message, { type: "error" });
        }
    }

    /**
     * Peforms an open/close raffle
     */
    async function performOpenClose() {
        // If the raffle isn't ended
        if (record?.status !== "OPEN") {
            // Open it then
            await update("raffles", {
                id: record?.id,
                data: {
                    status: "OPEN"
                }
            });

            return notify("As vendas do sorteio foram abertas.", {
                type: "success"
            });
        }

        // If the confirmation modal isn't visible yet
        if (!closeRaffleModalVisible) {
            // Show it
            return setCloseRaffleModalVisible(true);
        }

        try {
            await createActionRequest("post", "raffles", record.id, "endAndClear");

            notify("As vendas foram finalizadas e os números à venda sem pagamentos pendentes foram limpos.", { type: "success" });
        } catch(e) {
            notify((e as any).message, { type: "error" });
        }

        refresh();

        // Close the modal
        setCloseRaffleModalVisible(false);
    }

    const address = new URL(window.location.href.replace("admin.", ""));
    address.pathname = "/sorteio/" + record?.uuid;

    const [isDrawing, setIsDrawing] = useState(false);

    const handleRecalculate = async () => {
        try {
            await createActionRequest("post", "raffles", record.id, "recalculateResultHash");

            notify("Recalculado com sucesso!", { type: "success" });
        } catch(e) {
            notify((e as any).message, { type: "error" });
        }
    };

    const handleDeleteFiles = async () => {
        try {
            const response = await createActionRequest("post", "raffles", record.id, "files/delete");
            const json = await response.json();

            const failedFiles = json.filter((r) => !r.deleted && r.exists);

            if (failedFiles.length) {
                notify("Todos os arquivos foram apagados com sucesso!", { type: "success" });
            } else {
                notify(
                    `Os seguintes arquivos falharam ao ser apagados:\n${failedFiles.map((r) => r.name)}`,
                    { type: "warning" }
                );
            }

            setShowDeleteFilesConfirmation(false);
        } catch(e) {
            notify((e as any).message, { type: "error" });
        }
    };

    const displayURL = new URL(window.location.href);

    // Clear the hash
    displayURL.hash = "";

    // If in dev mode
    if (Configuration.isDev) {
        // Point to the local display
        displayURL.host = "localhost:8070";

        // Set the token
        displayURL.searchParams.set("access_token", localStorage.getItem("admin-token") as string);
    } else {
        // Point to the remote display
        displayURL.pathname = "/sorteador";
    }

    // Set the raffle_id param to the current raffle ID
    displayURL.searchParams.set("raffle_id", String(record?.id));

    return (
        <TopToolbar>
            <>
                <Confirm
                    isOpen={closeRaffleModalVisible}
                    title="Finalizar vendas do sorteio"
                    content="Você tem certeza que deseja finalizar as vendas, apagando todos os números da sorte à venda sem um pagamento atrelado?"

                    confirm="Sim"
                    cancel="Não"

                    onConfirm={() => performOpenClose()}
                    onClose={() => setCloseRaffleModalVisible(false)}
                />

                <Confirm
                    isOpen={integrityCheckModalVisible}
                    title="Verificação de integridade"
                    content="Tem certeza? Esta operação pode levar um longo tempo. Correções serão aplicadas caso algum número esteja sem dezenas."

                    confirm="Iniciar"
                    cancel="Fechar"

                    onConfirm={() => performMatrixIntegrityCheck()}
                    onClose={() => setIntegrityCheckModalVisible(false)}
                />

                <Confirm
                    isOpen={showDeleteFilesConfirmation}
                    title="Apagar arquivos"
                    content="Tem certeza que deseja apagar todos os arquivos do sorteio? Caso alguma operação de geração de arquivos esteja em andamento, ela será afetada."

                    confirm="Apagar"
                    cancel="Cancelar"

                    onConfirm={() => handleDeleteFiles()}
                    onClose={() => setShowDeleteFilesConfirmation(false)}
                />

                <GenerateNumbersAction raffleId={record?.id} />

                <Button
                    label="Arquivos de sorteio"
                    aria-haspopup="true"
                    onClick={(e) => setDownloadAnchorEl(e.target as any)}
                />

                <Button
                    label="Exportar como"
                    aria-haspopup="true"
                    onClick={(e) => setExportAnchorEl(e.target as any)}
                />

                <Button
                    label="Ações"
                    aria-haspopup="true"
                    onClick={(e) => setActionsAnchorEl(e.target as any)}
                />

                <Menu
                    id="actions-menu"
                    anchorEl={actionsAnchorEl}
                    keepMounted
                    open={Boolean(actionsAnchorEl)}
                    onClose={() => {
                        setActionsAnchorEl(null);
                    }}
                >
                    {
                        isDrawing && <RaffleDraw open={isDrawing} setOpen={setIsDrawing} />
                    }

                    <MenuItem
                        onClick={() => performOpenClose()}
                    >
                        { record?.status === "ENDED" ? <i className="mr-2 fa fa-fw fa-lock"></i> : <i className="mr-2 fa fa-fw fa-lock-open"></i> }
                        { record?.status === "ENDED" ? "Reabrir vendas" : "Finalizar vendas" }
                    </MenuItem>

                    <MenuItem
                        onClick={() => handleRecalculate()}
                    >
                        <i className="mr-2 fa fa-fw fa-refresh"></i> Recalcular hash
                    </MenuItem>

                    {
                        record?.status === "DRAFT" && <MenuItem
                            onClick={() => setGenerateNumbersOpen(true)}
                            disabled={record?.hasGeneratedNumbers}
                        >
                            <i className="mr-2 fa fa-fw fa-shuffle"></i> Gerar números
                        </MenuItem>
                    }

                    {
                        record?.status === "ENDED" && <MenuItem
                            onClick={() => setIsDrawing(true)}
                        >
                            <i className="mr-2 fa fa-fw fa-shuffle"></i> Realizar sorteio
                        </MenuItem>
                    }

                    {
                        record?.status === "ENDED" && <a
                            href={displayURL.toString()}
                            target="_blank"

                            onClick={() => setActionsAnchorEl(null)}
                        >
                            <MenuItem>
                                <i className="mr-2 fa fa-fw fa-arrow-up-right-from-square"></i> Abrir no display
                            </MenuItem>
                        </a>
                    }

                    {
                        record?.status === "ENDED" && <a
                            href={displayURL.toString()}
                            target="_blank"

                            onClick={() => setActionsAnchorEl(null)}
                        >
                            <MenuItem>
                                <i className="mr-2 fa fa-fw fa-arrow-up-right-from-square"></i> Abrir no sorteador
                            </MenuItem>
                        </a>
                    }

                    {
                        Configuration.isDev &&
                            <MenuItem onClick={() => clearRaffleWinners()}>
                                <i className="mr-2 fa fa-fw fa-broom"></i> Limpar resultados e números chamados
                            </MenuItem>
                    }

                    <MenuItem
                        onClick={() => setIntegrityCheckModalVisible(true)}
                    >
                        <i className="mr-2 fa fa-fw fa-wrench"></i> Solucionar problemas de matriz
                    </MenuItem>
                </Menu>

                <Menu
                    id="export-menu"
                    anchorEl={exportAnchorEl}
                    keepMounted
                    open={Boolean(exportAnchorEl)}
                    onClose={() => {
                        setExportAnchorEl(null);
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            setExportAnchorEl(null);
                            performExport("ValeSorte");
                        }}
                    >
                        Lista para sorteio
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            setExportAnchorEl(null);
                            performExport("ViaCapVND");
                        }}
                    >
                        Vendidos V2 / ViaCap
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            setExportAnchorEl(null);
                            performExport("ViaCapVNDV3");
                        }}
                    >
                        Vendidos V3 / ViaCap
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            setExportAnchorEl(null);
                            performExport("ViaCapContemplados");
                        }}
                    >
                        Contemplados / ViaCap
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            setExportAnchorEl(null);
                            performExport("ViaCapReembolsoContemplados");
                        }}
                    >
                        Contemplados / Reembolso ViaCap
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            setExportAnchorEl(null);
                            performExport("ViaCapAta");
                        }}
                    >
                        Ata do Sorteio / ViaCap
                    </MenuItem>

                    <MenuItem
                        onClick={(e) => {
                            handleSubMenuClick(e);
                        }}
                    >
                        <ArrowLeftIcon className="mr-3" />
                        Ativos / ViaCap Incentivo
                    </MenuItem>
                </Menu>

                <Menu
                    id="downloads-menu"
                    anchorEl={downloadAnchorEl}
                    keepMounted
                    open={Boolean(downloadAnchorEl)}
                    onClose={() => setDownloadAnchorEl(null)}
                >
                    <MenuItem
                        onClick={() => setShowDeleteFilesConfirmation(true)}
                    >
                        Apagar arquivos gerados
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            setDownloadAnchorEl(null);
                            performDrawerFileDownload("header");
                        }}
                    >
                        Baixar cabeçalho
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            setDownloadAnchorEl(null);
                            performDrawerFileDownload("raffle");
                        }}
                    >
                        Baixar dados
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            setDownloadAnchorEl(null);
                            performDrawerFileDownload("users");
                        }}
                    >
                        Baixar usuários
                    </MenuItem>
                </Menu>

                <EditButton/>

                <Popover
                    open={Boolean(subMenuAnchor)}
                    anchorEl={subMenuAnchor}
                    onClose={handleSubMenuClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            handleSubMenuClose();
                            performExport("ViaCapAtivos", { customerCode: "DAILY" });
                        }}
                    >
                        Extração p/ sorteio diário
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            handleSubMenuClose();
                            performExport("ViaCapAtivos", { customerCode: "MAIN" });
                        }}
                    >Extração geral</MenuItem>
                </Popover>
            </>
        </TopToolbar>
    );
};

export const RaffleShow = () => (
    <Show
        actions={<RaffleShowActions/>}
        queryOptions={{
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false
        }}
    >
        <TabbedShowLayout>
            <Tab label="Informações">
                <NumberField source="id" />

                <TextField  source="uuid" />

                <TextField source="slug" />

                <TextField source="title" />

                <SelectField source="kind" choices={RaffleKinds} />

                <ImageField emptyText="Nenhuma imagem selecionada" label="Imagem destacada" source="thumbnail.src" />

                <NumberField source="price" options={{ style: "currency", currency: "BRL" }} />

                <SelectField source="status" choices={RaffleStatus} />

                <DateField source="startAt" />

                <DateField source="endAt" />
            </Tab>

            <Tab label="Dados técnicos">
                <TextField source="resultHash" />
            </Tab>

            <Tab label="Títulos físicos">
                <PhysicalBondsGenerationBox />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const PhysicalBondsGenerationBox = () => {
    const notify = useNotify();
    const { record } = useShowContext<Raffle>();
    const refresh = useRefresh();

    const [ranges, setRanges] = useState<ManagedRange[]>([]);
    const [isRangePickerModalOpen, setRangePickerModalOpen] = useState(false);
    const [selectedRange, setSelectedRange] = useState<ManagedRange | null>(null);

    let autoRefreshInterval: undefined | NodeJS.Timer;

    const startAutoRefresh = () => {
        stopAutoRefresh();

        autoRefreshInterval = setInterval(() => refresh(), 1000);
    };

    const stopAutoRefresh = () => {
        if (!autoRefreshInterval) {
            return;
        }

        clearInterval(autoRefreshInterval);
        autoRefreshInterval = undefined;
    };

    useEffect(() => {
        // Cleans up the auto refresher
        stopAutoRefresh();
    }, []);

    useEffect(() => {
        if (!record) return;

        // Auto-start the refresher if the generation is running
        if (record.physicalBondsGeneration?.isRunning) {
            startAutoRefresh();
        }

        return () => {
            // Cleanup function to stop the auto refresher
            stopAutoRefresh();
        };
    }, [record!.physicalBondsGeneration?.isRunning]);

    const generationPercentage = useMemo(() => {
        if (!record?.physicalBondsGeneration?.trackingState?.generatedBonds || !record.physicalBondsGeneration.trackingState.bondsToGenerate) {
            return 0;
        }

        return record.physicalBondsGeneration.trackingState.generatedBonds / record.physicalBondsGeneration.trackingState.bondsToGenerate;
    }, [
        record!.physicalBondsGeneration
    ]);

    /**
     * Generates the physical bonds of a raffle
     */
    async function generatePhysicalBonds(raffleId: number, rangeId: number) {
        startAutoRefresh();

        try {
            await createActionRequest("post", "raffles", raffleId, "generatePhysicalBonds", {
                rangeId
            });

            notify(`O processo de geração foi concluído`, { type: "success" });
        } catch (e) {
            notify((e as any).message, { type: "error" });
        } finally {
            // Stops the refresh
            stopAutoRefresh();

            // Also refreshes
            refresh();
        }
    }

    const handleGenerateConfirm = async () => {
        if (!selectedRange) {
            return;
        }

        generatePhysicalBonds(record!.id, selectedRange.id);

        handleClose();
    };

    const fetchRangesForCategory = async (categoryId: number) => {
        try {
            const rafflePhysicalRanges = await createDataProviderRequest("get", "managedPhysicalRanges", null, {
                query: {
                    filter: {
                        kind: "PHYSICAL_SALE"
                    }
                }
            })
                .then((res) => res.json())
                .then((allRanges) => allRanges.filter(
                    (range) => range.filters?.categories?.some((id: any) => id.toString() === categoryId.toString())
                ));

            setRanges(rafflePhysicalRanges);
        } catch (error) {
            console.error(error);
        }
    };

    async function selectRaffleToGenerate() {
        await fetchRangesForCategory(record!.categoryId);
        setRangePickerModalOpen(true);
    }

    const handleClose = () => {
        setRangePickerModalOpen(false);
        setSelectedRange(null);
    };

    return <>
        <Modal
            open={isRangePickerModalOpen}
            onClose={handleClose}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "90%",
                    maxWidth: 600,
                    bgcolor: "background.paper",
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Selecionar Lote Gerenciado
                </Typography>

                <Typography variant="body1" paragraph>
                    Selecione um lote gerenciado da lista abaixo.
                </Typography>

                <Select
                    value={selectedRange?.id || "initial"}
                    fullWidth

                    onChange={(e) => {
                        const range = ranges.find((range) => range.id.toString() === e.target.value.toString());
                        setSelectedRange(range || null);
                    }}
                >
                    <MenuItem value="initial">
                        Selecione um Lote Gerenciado
                    </MenuItem>

                    {
                        ranges.map((range) => (
                            <MenuItem key={range.id} value={range.id}>
                                {range.fromBatch} &mdash; {range.toBatch}
                            </MenuItem>
                        ))
                    }
                </Select>

                <Box mt={3} display="flex" justifyContent="space-between">
                    <Button onClick={handleClose} label="Cancelar"/>

                    <Button
                        label="Confirmar"
                        onClick={handleGenerateConfirm}
                        disabled={!selectedRange}
                    />
                </Box>
            </Box>
        </Modal>

        <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
            >
                <InfoIcon className="mr-2 opacity-50 text-primary" />

                <Typography component="span">
                    Detalhes técnicos da geração
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    record?.physicalBondsGeneration
                        ? <div className="text-sm">
                            {
                                record.physicalBondsGeneration?.isRunning &&
                                    <div className="flex flex-nowrap">
                                        <PingingBall />
                                        <span className="ml-2">Gerando títulos físicos...</span>
                                    </div>
                            }
                            {
                                <div className="space-y-4">
                                    {
                                        record.physicalBondsGeneration?.hasAnyGenerated
                                            ? <div>Contém títulos físicos gerados</div>
                                            : <div>Nenhum título físico gerado</div>
                                    }

                                    {
                                        record.physicalBondsGeneration?.trackingState
                                            ? <>
                                                <div>
                                                    <div>Iniciado em: {
                                                        mapIf(
                                                            record.physicalBondsGeneration.trackingState.startedAt,
                                                            (date) => new Date(date).toLocaleString(),
                                                            "Nunca iniciou"
                                                        )
                                                    }</div>
                                                    <div>Atualizado em: {
                                                        mapIf(
                                                            record.physicalBondsGeneration.trackingState.updatedAt,
                                                            (date) => new Date(date).toLocaleString(),
                                                            "Nunca"
                                                        )
                                                    }</div>
                                                </div>

                                                <div>
                                                    <div>Status: <strong>{ GenerationStatuses[record.physicalBondsGeneration.trackingState.status as any] }</strong></div>
                                                    {
                                                        mapIf(
                                                            record.physicalBondsGeneration.trackingState.statusExplanation,
                                                            (statusExplanation) => <em>{ statusExplanation }</em>
                                                        )
                                                    }
                                                    {
                                                        mapIf(
                                                            record.physicalBondsGeneration.trackingState.finishedAt,
                                                            (finishedAt) => <div>Finalizado em: { new Date(finishedAt).toLocaleString() }</div>
                                                        )
                                                    }
                                                    {
                                                        mapIf(
                                                            Number(record.physicalBondsGeneration.trackingState.eta),
                                                            (eta) => <div>Tempo restante: { eta.toFixed(0) } segundos</div>
                                                        )
                                                    }
                                                </div>

                                                {/* Progress bar */}
                                                <div className="relative w-full h-5 overflow-hidden rounded-md bg-slate-100">
                                                    <div className="h-full bg-primary" style={{ width: generationPercentage * 100 + "%" }}></div>
                                                    <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
                                                        { (generationPercentage * 100).toFixed(2) }%
                                                    </div>
                                                </div>

                                                <div>
                                                    <div>Títulos gerados: { record.physicalBondsGeneration.trackingState.generatedBonds ?? "-" }</div>
                                                    <div>Nó iniciador: { record.physicalBondsGeneration.trackingState.starterNodeId ?? "-" }</div>
                                                </div>
                                            </>
                                            : <><div>Não há tracking de geração recente</div></>
                                    }

                                </div>
                            }
                        </div>
                        : <div>Não há dados de geração de títulos físicos</div>
                }
            </AccordionDetails>
        </Accordion>

        <div className="flex w-full gap-6 mt-4">
            <Button
                label="Gerar títulos físicos"
                onClick={() => selectRaffleToGenerate()}
                disabled={record!.physicalBondsGeneration?.isRunning || record?.status !== "DRAFT"}
                size="small"
                color="primary"
                variant="contained"
                startIcon={<BoltIcon />}
            />

            <Button
                label="Exportar formato v2"
                onClick={() => downloadPhysicalBonds(record!.id)}
                size="small"
                color="primary"
                variant="contained"
                startIcon={<DownloadIcon />}
                disabled={!record!.physicalBondsGeneration?.hasAnyGenerated}
            />

            <div className="ml-auto">
                <ClearPhysicalBondsButton disabled={record?.status !== "DRAFT"} />
            </div>
        </div>
    </>;
}

/**
 * Generates physical bonds PSB file.
 * @param raffleId (optional) The raffle ID.
 */
function downloadPhysicalBonds(raffleId: number) {
    const url: URL = new URL(
        generateAuthenticatedURL("/raffles/" + raffleId + "/actions/exportPhysicalBonds")
    );

    window.open(url);
}

const ClearPhysicalBondsButton = (props: { disabled?: boolean }) => {
    const { record } = useShowContext();

    const notify = useNotify();
    const refresh = useRefresh();

    const clearPhysicalBonds = async () => {
        if (!window.confirm(`Deseja apagar todos os títulos físicos do sorteio ${record.id}?`)) {
            return;
        }

        try {
            await createActionRequest("post", "raffles", record.id, "clearPhysicalBonds");

            notify("Títulos físicos apagados.", { type: "success" });
        } catch(e) {
            notify((e as any).message, { type: "error" });
        }

        refresh();
    };

    return (
        <>
            <Button
                startIcon={<DeleteIcon />}
                variant="contained"
                label="Apagar títulos físicos"
                onClick={clearPhysicalBonds}
                disabled={props.disabled}
            />
        </>
    );
};

const PingingBall = () => {
    return (
        <div className="flex items-center justify-center">
            <div className="relative">
                <div className="absolute w-4 h-4 bg-green-500 rounded-full animate-ping"></div>
                <div className="w-4 h-4 bg-green-500 rounded-full"></div>
            </div>
        </div>
    );
};
